<template>
  <div class="bloc-modale" v-if="revele">
    <div class="overlay" @click="toggleModale"></div>

    <div class="modale">
        <div @click="toggleModale" class="fas close">&#xf00d;</div>
        <div class="modale_general">


            <!--Icon part-->
            <div class="icon_up_container">
                <div v-if="type == 'supprimer'" class="far trash_icon icon_up">&#xf2ed;</div>
                <div v-if="type == 'restaurer'" class="fas restart_icon icon_up">&#xf01e;</div>
                <div v-if="type == 'info'" class="fas check icon_up">&#xf00c;</div>
                <div v-if="type == 'echec'" class="fas fail_icon icon_up">&#xf00d;</div>
                <div v-if="type == 'chargement'" class="fas fa-pulse restart_icon icon_up">&#xf110;</div>
                <div v-if="type == 'forgotpwd' || type == 'newpwd'" class="fas restart_icon icon_up">&#xf502;</div>
            </div>


            <!--Title/content part-->
            <div class="title">{{ getTitlePopup() }}</div>
            <div class="content" v-if="getContentPopup()">{{ getContentPopup() }}</div>

            <div v-if="type == 'forgotpwd' && revele.showIrame">
                <!--<input type="text" v-model="revele.input"/>-->
                <iframe id="lostpasswordiframe" src="/wp-login.php?action=lostpassword" frameBorder="0" height="0px" width="0px" @load="setNewPwdLinkIframeContent"></iframe>
            </div>

            <div v-if="type=='newpwd' && revele.key && revele.login && revele.showIrame" class="invisibleIframeContainer">
                <!--<input type="text" v-model="revele.input"/>-->
                <iframe id="newpasswordiframe" :src="'/wp-login.php?action=rp&key=' + revele.key + '&login=' + revele.login" frameBorder="0" height="0px" width="0px" @load="setNewPwdIframeContent"></iframe>
            </div>


            <!--Input part-->
            <div v-if="type=='newpwd'">
                <div class="contentNewPwdContainer" v-if="!revele.showIrame  && !revele.done">
                    <div class="contentNewPwd">
                        <div class="half">
                            <input type="password" :placeholder="page_popup().placeholders.placeholder1" v-model="revele.pwd1"/>
                            <div class="progressBarContainer">
                                <div id="progressBar" class="progressBar"></div>
                            </div>
                        </div>
                        <div class="half">
                            <input type="password" :placeholder="page_popup().placeholders.placeholder2"  v-model="revele.pwd2"/>
                        </div>
                    </div>
                    <div class="newpwdError" :class="{visible: revele.error}">{{ revele.error }}</div>
                </div>
                <div class="contentNewPwdDone" v-else>{{ page_popup().messages.modified_pwd_succes }}</div>
            </div>

            <div v-if="type=='forgotpwd' && !revele.showIrame  && !revele.done">
                <input type="text" :placeholder="page_popup().placeholders.placeholder1" v-model="revele.mail"/>
            </div>




            <!--Bottom part-->
            <div class="bottom" v-if="type == 'supprimer'">
                <span @click="toggleModale" class="modaleButton modaleButtonHalf modaleButtonBackground">{{ page_popup().boutons.annuler }}</span>
                <span v-if="!revele.chargement" @click="envoyerReponse" class="modaleButton modaleButtonHalf modaleButtonNoBackground">{{ page_popup().boutons.supprimer }}</span>
                <span v-else class="modaleButton modaleButtonHalf modaleButtonNoBackground">. . .</span>
            </div>

            <div class="bottom" v-if="type == 'restaurer'">
                <span @click="toggleModale" class="modaleButton modaleButtonHalf modaleButtonBackgroundGreen">{{ page_popup().boutons.annuler }}</span>
                <span v-if="!revele.chargement" @click="envoyerReponse" class="modaleButton modaleButtonHalf modaleButtonNoBackgroundGreen">{{ page_popup().boutons.restaurer }}</span>
                <span v-else class="modaleButton modaleButtonHalf modaleButtonNoBackgroundGreen">. . .</span>
            </div>

            <div class="bottom" v-if="type == 'info' || type=='echec'">
                <span @click="toggleModale" class="modaleButton modaleButtonFull modaleButtonBackground">{{ page_popup().boutons.accord }}</span>
            </div>
            <div class="bottom" v-if="type == 'chargement'"></div>

            <div class="forgotpwdBottom" v-if="type == 'forgotpwd'">
                <div class="bottom"  v-if="!revele.showIrame && !revele.done">
                    <span @click="toggleModale" class="modaleButton modaleButtonHalf modaleButtonBackgroundGreen">{{ page_popup().boutons.annuler }}</span>
                    <span @click="sendNewPwdLink" class="modaleButton modaleButtonHalf modaleButtonNoBackgroundGreen">{{ page_popup().boutons.envoyer }}</span>
                </div>
                <div class="bottom" v-else>
                    <span @click="toggleModale" class="modaleButton modaleButtonFull modaleButtonBackground">{{ page_popup().boutons.accord }}</span>
                </div>
            </div>

            <div class="bottom" v-if="type=='newpwd'">
                <div class="bottom" v-if="!revele.showIrame && !revele.done">
                    <span @click="toggleModale" class="modaleButton modaleButtonHalf modaleButtonBackgroundGreen">{{ page_popup().boutons.annuler }}</span>
                    <span @click="sendNewPwd" class="modaleButton modaleButtonHalf modaleButtonNoBackgroundGreen">{{ page_popup().boutons.enregistrer }}</span>
                </div>
                <div class="bottom" v-else>
                    <span @click="toggleModale" class="modaleButton modaleButtonFull modaleButtonBackground">{{ page_popup().boutons.accord }}</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
import page_pop from '@/json/page_popup.json'
export default {
    name: "Modale",
    props: ["revele", "toggleModale", "title", "content", "type", "sendResponse"],
    data() {
        return {
            page_pop: page_pop,
        }
    },
    methods:{
        page_popup(){
            return page_pop[this.$store.state.codeLangue]
        },

        getTitlePopup(){
            var tab = this.title.split("#")

            //Si on a un titre prédéfini
            if(this.page_popup()[tab[0]].hasOwnProperty("title")){
                //Si on a un param en plus
                if(tab.length>1){
                    return this.page_popup()[tab[0]].title + tab[1]
                }
                else{
                    return this.page_popup()[tab[0]].title
                }
            }
            else{
                console.log(this.type)
                return this.page_popup()[this.type].title
            }
        },

        getContentPopup(){
            var tab = this.content.split("#")

            //Si on a un param
            if(tab.length>1){
                return this.page_popup()[tab[0]].desc + tab[1]
            }
            else{
                return this.page_popup()[tab[0]].desc
            }
        },

        envoyerReponse(){
            //On affiche le chargement
            this.revele.chargement=true
            //On execute la fonction passée en paramètre
            this.sendResponse(this.title)
        },

        sendNewPwdLink(){
            if(this.revele.mail){
                this.$emit("changercontent", "connexion_info_send_new_pwd#" + this.revele.mail)
                this.revele.showIrame=true
            }
        },

        setNewPwdLinkIframeContent(){
            console.log("called")
            //D'abord on set les values
            var iframe = document.getElementById("lostpasswordiframe");
            var iframeDoc = (iframe.contentWindow || iframe.contentDocument);
            if (iframeDoc.document) iframeDoc = iframeDoc.document;

            if(iframeDoc.getElementById("user_login")){
                iframeDoc.getElementById("user_login").value = this.revele.mail

                console.log(iframeDoc)
                
                iframeDoc.getElementById('lostpasswordform').submit();

                //this.revele.showIrame=false
                this.revele.done = true
                //this.revele.showIrame=false
            }
        },

        iframeStyles() {
            console.log("called")
            let iframe = document.getElementById("lostpasswordiframe");
            let doc = iframe.contentDocument;
            doc.head.innerHTML = doc.head.innerHTML + '<style>#login > h1{display: none;} #lostpasswordform, #lostpasswordform > p, body{margin: 0px;} body{text-align:center} input:focus-visible, select:focus-visible{outline: #42b983 auto 1px;}input, input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus{font-family: "Lato";font-style: normal;font-weight: 400px;font-size: 14px;line-height: 17px;width: 180px;padding-top: 6px;padding-left: 8px;padding-right: 8px;padding-bottom: 6px;background-color: transparent;border: 1px solid #000000;box-sizing: border-box;border-radius: 5px;-webkit-box-shadow: 0 0 0px 1000px #ffffff inset;}</style>';
            doc.head.innerHTML = doc.head.innerHTML + '<link href="https://fonts.googleapis.com/css2?family=Lato&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap" rel="stylesheet">'
            iframe.style.height = iframe.contentDocument.body.offsetHeight + 'px';
            iframe.style.width = iframe.contentDocument.body.offsetWidth + 'px';
            console.log("iframe style fully loaded")
        },

        sendNewPwd(){
            var force = this.$store.getters.getPasswordStrength(this.revele.pwd1)

            if(this.revele.pwd1==""){
                this.revele.error=this.page_popup().sendNewPwd_errors.no_pwd
            }
            else if(force.pourcentageProgressBar<100){
                this.revele.error=this.page_popup().sendNewPwd_errors.too_weak
            }
            else if(this.revele.pwd2==""){
                this.revele.error=this.page_popup().sendNewPwd_errors.no_confirm
            }
            else if(this.revele.pwd1!=this.revele.pwd2){
                this.revele.error=this.page_popup().sendNewPwd_errors.not_identical
            }
            else{
                this.revele.error=false
                this.revele.showIrame=true
            }
        },

        setNewPwdIframeContent(){
            console.log("called")
            //D'abord on set les values
            var iframe = document.getElementById("newpasswordiframe");
            var iframeDoc = (iframe.contentWindow || iframe.contentDocument);
            if (iframeDoc.document) iframeDoc = iframeDoc.document;

            if(iframeDoc.getElementById("pass1")){
                iframeDoc.getElementById("pass1").value = this.revele.pwd1
                iframeDoc.getElementById("pass1").setAttribute("data-pw", this.revele.pwd1)

                iframeDoc.getElementById("pass2").value = this.revele.pwd1
                iframeDoc.getElementById("pass2").setAttribute("data-pw", this.revele.pwd1)

                iframeDoc.getElementById("pw-weak").checked = true;

                console.log(iframeDoc)
                
                iframeDoc.getElementById("wp-submit").click();

                //this.revele.showIrame=false
                this.revele.done = true
                //this.revele.showIrame=false
            }
        },

        getPasswordStrength(){
            let progressBar = document.getElementById("progressBar");
            if(this.revele.pwd1){
                var force = this.$store.getters.getPasswordStrength(this.revele.pwd1)
            }
            else{
                var force = {pourcentageCouleur: 1, pourcentageProgressBar: 0}
            }
            var red = this.$store.getters.getRedHex({pourcentage: force.pourcentageCouleur, bluehex: 60})
            var green = this.$store.getters.getGreenHex({pourcentage: force.pourcentageCouleur, bluehex: 60})

            progressBar.style.width = force.pourcentageProgressBar + "%"
            progressBar.style.background = "rgb(" + red + ", " + green + ", 60)"
        }
    },
    watch:{
        revele: {
            handler(val, oldVal){
                if(val.hasOwnProperty("pwd1")){
                    this.getPasswordStrength()
                }
            },
            deep: true
        }
    },
};
</script>


<style scoped>
.invisibleIframeContainer{
    height: 0px;
    width: 0px;
}

.contentNewPwdDone{
    margin-bottom: 20px;
    margin-top: 20px;
}

.progressBarContainer{
    width: 100%;
    height: 8px;
    margin-top: 4px;
    border-radius: 4px;
    background: rgb(0,0,0,.1);
    overflow: hidden;
}

.progressBar{
    height: 100%;
    width: 0%;
}

.visible{
    visibility: visible;
}

.newpwdError{
    color: #F2505D;
    visibility: hidden;
    margin-bottom: 10px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.contentNewPwdContainer{
    width:100%;
}

.contentNewPwd{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
}

.forgotpwdBottom{
    margin-top:20px;
}
.bloc-modale {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:1;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modale {
  background: #FFFFFF;
  position: fixed;
  top: 30%;
  width: 450px;
  min-height: 320px;
  border-radius: 10px;
}

.modale_general{
    margin-right: 50px;
    margin-left: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #CCCBCB;
  font-size: 26px;
  cursor: pointer;
  transition: all .2s
}

.close:hover {
    transform: scale(1.3);
    color: #F2505D
}

.icon_up_container{
    display: inline-block;
}

.icon_up{
    margin-top: 40px;
    border-radius: 50%;
    font-size: 30px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF
}

.trash_icon{
    background-color: #022873;
}

.check{
    background-color: #42b983;
}

.restart_icon{
    background-color: #022873;
}

.fail_icon{
    background-color: #F2505D
}

.title{
    margin-top: 32px;
    font-family: "Lato";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}

.content{
    margin-top: 12px;
    margin-bottom: 12px;
    font-family: "Lato";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.bottom{
    text-align: center;
    display: flex;
    align-items: center;
}

.modaleButton{
    padding-top:12px;
    padding-bottom:12px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.modaleButtonHalf{
    min-width:100px;
    margin-right: 12px;
    margin-left: 12px;
}

.modaleButtonHalf:hover{
    transform: scale(1.1)
}

.modaleButtonBackground{
    filter: drop-shadow(0px 2px 4px #F2505D);
    color: #FFFFFF;
    background-color: #F2505D;
}

.modaleButtonNoBackground{
    box-shadow: 0px 0px 10px #F2505D;
    color: #F2505D;
    border: 1px solid #F2505D
}

.modaleButtonBackgroundGreen{
    filter: drop-shadow(0px 2px 4px #42b983);
    color: #FFFFFF;
    background-color: #42b983;
}

.modaleButtonNoBackgroundGreen{
    box-shadow: 0px 0px 10px #42b983;
    color: #42b983;
    border: 1px solid #42b983
}

.modaleButtonFull{
    min-width: 150px;
}

.modaleButtonFull:hover{
    transform: scale(1.1)
}

input[type=text], input[type=password]{
    background: transparent;
    border: 1px solid #000000;
    border-radius: 5px;
    padding-top: 4px;
    padding-left: 4px;
    padding-bottom: 4px;
    width: 100%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
}

input::placeholder{
    color:#CCCBCB;
}

.half{
    width: 45%
}

/*Pour l'iframe */

#login > h1{
    display: none;
}

#lostpasswordform > p{
    margin: 0px;
}

#lostpasswordiframe{
    margin-top: 20px;
}

@media screen and (max-width: 450px) {
  .modale{
      width:100%;
      border-radius: 0px;
  }
}
</style>